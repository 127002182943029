/** @format */

export default {
  data() {
    return {
      showCashSelectDialog: false,
    };
  },
  methods: {
    onSelectOperation(e) {
      if (!e.value) return;
      console.log({ e, data: this.data });
      this.$refs.cashDialog.openDialog(e.id, 0, this.data, { operation_name: e.text });
    },
    onClickCash(d) {
      console.log();
      this.$refs.cashDialog.openDialog(d.row.operation_type, d.row.id, this.data);
    },
  },
};
